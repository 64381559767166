<div class="form-group">
	<div *ngIf="!requesterOnly || requesterAndPurchaser" class="d-flex mt-3">
		<input type="checkbox" id="acceptTermsInput" name="acceptTerms" class="position-static"
			[(ngModel)]="acceptTerms" required>
		<div>
			<label for="acceptTermsInput" class="m-0 font-weight-normal">
				<span class="textTerm">
					{{"checkOut.clicking"|cxTranslate}}
					<em>
						{{"checkOut.placeOrder"|cxTranslate}}
					</em>
					{{"checkOut.youAreAgree"|cxTranslate}}
				</span>
				<span class="textTerm link">
					<a href="/purchase-terms-and-conditions" target="_blank" class="d-inline-block font-weight-normal">
						{{"checkOut.purchaseTerms"|cxTranslate}}
					</a>
				</span>
			</label>
			<div *ngIf="!acceptTerms && isSubmitAttempted">
				<label class="validation-error-text font-weight-normal">
					{{"checkOut.accepting"|cxTranslate}}
				</label>
			</div>
		</div>
	</div>
</div>
<div class="mb-3">
	<div *ngIf="!isReviewMode">
		<button *ngIf="requesterOnly && !requesterAndPurchaser" [disabled]="(!QuotationMode && !nonQuatationPresent)"
			(click)="onRequestOrder()" class="btn cstm-btn-primary mr-3 mb-2">
			{{"checkOut.requestOrder"|cxTranslate}}
		</button>
		<button *ngIf="!requesterOnly || requesterAndPurchaser" [disabled]="(!QuotationMode && !nonQuatationPresent)"
			(click)="onPlaceOrder()" class="btn cstm-btn-primary mr-3 mb-2">
			{{"checkOut.placeOrder"|cxTranslate}}
		</button>
		<a *ngIf="!purchaserOnly && (requesterOnly || requesterAndPurchaser)" (click)="onDuplicateCart()"
			class="cstm-btn-link mr-3 mb-2 duplicate-cart-btn">
			{{"checkOut.duplicateCart"|cxTranslate}}
		</a>
	</div>
	<div *ngIf="isReviewMode">
		<button (click)="onApproveOrderRequest()" class="btn cstm-btn-primary mr-3 mb-2">
			{{"checkOut.approveOrderRequest"|cxTranslate}}
		</button>
		<button (click)="onRejectOrder()" class="btn cstm-btn-light mr-3 mb-2">
			{{"checkOut.rejectOrderRequest"|cxTranslate}}
		</button>
		<a *ngIf="!purchaserOnly" (click)="onDuplicateCart()" class="cstm-btn-light mr-3 mb-2 duplicate-cart-btn">
			{{"checkOut.duplicateCart"|cxTranslate}}
		</a>
	</div>
</div>
<!-- Modals -->
<div #duplicateModal class="modal fade" tabindex="-1" id="duplicateModal" aria-labelledby="duplicateModal"
	aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header d-flex justify-content-between align-items-center">
				<h4 class="m-0">
					{{"checkOut.duplicateCart"|cxTranslate}}
				</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<span>
					{{"checkOut.creatingDuplicate"|cxTranslate}}
				</span>
			</div>
			<div class="modal-body">
				<form>
					<fieldset class="form-group">
						<label for="company-input">
							{{"checkOut.name"|cxTranslate}}
							<span class="validation-error-text">*</span>
						</label>
						<input id="companyInput" type="text" [ngClass]="{'input-error': isError}"
							placeholder="{{'checkOut.enterCartName'|cxTranslate}}" required class="form-control">
					</fieldset>
					<fieldset class="form-group">
						<label for="legal-entity-select">
							{{"checkOut.legalEntities"|cxTranslate}}
							<span class="validation-error-text">*</span>
						</label>
						<select [(ngModel)]="selectedLegalEntitySession" [ngModelOptions]="{standalone: true}"
							(change)="onValueChange($event)" id="legal-entity-select" required class="custom-select">
							<option *ngFor="let legalEntity of legalEntityListSession" [ngValue]="legalEntity">
								{{ legalEntity }}
							</option>
						</select>
					</fieldset>
				</form>
			</div>
			<div class="modal-footer d-flex justify-content-start">
				<button type="button" class="btn cstm-btn-light mb-2" data-dismiss="modal">
					{{"checkOut.cancel"|cxTranslate}}
				</button>
				<button (click)="confirmDuplicateCart()" type="button" class="btn cstm-btn-primary mb-2 ml-2">
					{{"checkOut.duplicate"|cxTranslate}}
				</button>
			</div>
		</div>
	</div>
</div>
<div #approveOrderModal class="modal fade" tabindex="-1" id="approveOrderModal" aria-labelledby="approveOrderModal"
	aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header d-flex justify-content-between align-items-center">
				<h4 class="m-0" *ngIf="requesterAndPurchaser || purchaserOnly">
					{{"checkOut.orderPlaced"|cxTranslate}}
				</h4>
				<h4 class="m-0" *ngIf="requesterOnly">
					{{"checkOut.orderRequestPlaced"|cxTranslate}}
				</h4>
				<button (click)="redirectToOrder()" type="button" class="close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<span *ngIf="requesterOnly">
					{{"checkOut.orderRequestHasBeenPlaced"|cxTranslate}}
				</span>
				<span *ngIf="requesterAndPurchaser || purchaserOnly">
					{{"checkOut.orderHasBeenPlaced"|cxTranslate}}
					{{"checkOut.itMightTakeTimeToBeRegisteredInSystem"|cxTranslate}}
				</span>
			</div>
			<div class="modal-footer d-flex justify-content-start">
				<button (click)="redirectToOrder()" type="button" class="btn cstm-btn-primary mb-2 ml-2">
					{{"checkOut.ok"|cxTranslate}}
				</button>
			</div>
		</div>
	</div>
</div>
<div #rejectorderModal class="modal fade" tabindex="-1" id="rejectorderModal" aria-labelledby="rejectorderModal"
	aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header d-flex justify-content-between align-items-center">
				<h4 class="m-0">
					{{"checkOut.rejectOrder"|cxTranslate}}
				</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close"
					(click)="cancelRejectRequest()">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<span>
					{{"checkOut.rejectOrderReq"|cxTranslate}}
				</span>
				<textarea id="commentinput" [(ngModel)]="commentInput" placeholder="{{'checkOut.yourComment'|cxTranslate}}" rows="2" wrap="soft"
					class="form-control" #commentinput></textarea>
			</div>
			<div class="modal-footer d-flex justify-content-start">
				<button type="button" class="btn cstm-btn-light mb-2" data-dismiss="modal"
					(click)="cancelRejectRequest()">
					{{"checkOut.Cancel"|cxTranslate}}
				</button>
				<button (click)="onRejectOrderRequest()" type="button" class="btn cstm-btn-primary mb-2 ml-2">
					{{"checkOut.confirm"|cxTranslate}}
				</button>
			</div>
		</div>
	</div>
</div>
<div #minimumOrderCartModal class="modal fade" tabindex="-1" id="minimumOrderCartModal"
	aria-labelledby="minimumOrderCartModal" aria-hidden="true">
	<div class="modal-dialog">
		<div class="modal-content">
			<div class="modal-header d-flex justify-content-between align-items-center">
				<h4 class="m-0">
					{{"cartPage.orderAmountToLow" |cxTranslate}}
				</h4>
				<button type="button" class="close" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">&times;</span>
				</button>
			</div>
			<div class="modal-body">
				<p>
					{{"cartPage.yourCurrentTotal" |cxTranslate}} {{currentTotal | number:'1.0-0'}} but order less than
					{{minimumOrderAmount | number:'1.0-0'}} can't be placed.{{"cartPage.PleaseAddMore" |cxTranslate}}
				</p>
			</div>
			<div class="modal-footer d-flex justify-content-start">
				<button type="button" class="btn cstm-btn-light mb-2" data-dismiss="modal">
					{{"cartPage.close" |cxTranslate}}
				</button>
				<button (click)="navigateToProducts()" type="button" class="btn cstm-btn-primary mb-2 ml-2">
					{{"cartPage.continueShopping" |cxTranslate}}
				</button>
			</div>
		</div>
	</div>
</div>